import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    redirect: { name: 'Login' },
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "Login" */ '../views/Login.vue')
  },
  {
    path: '/admin',
    name: 'Admin',
    component: () =>
      import(/* webpackChunkName: "Admin" */ '../views/admin/Index.vue'),
    children: [
      {
        path: '/admin',
        redirect: { name: 'MerchantList' },
      },
      {
        path: '/merchant/list',
        name: 'MerchantList',
        meta: {
          shouldLogin: true,
          isList: true
        },
        component: () => import(/* webpackChunkName: "MerchantList" */ '../views/admin/merchant/MerchantList.vue')
      },
      {
        path: '/platform/list',
        name: 'PlatformList',
        meta: {
          shouldLogin: true,
          isList: true
        },
        component: () => import(/* webpackChunkName: "PlatformList" */ '../views/admin/platform/PlatformList.vue')
      },
      {
        path: '/system/config',
        name: 'SystemConfig',
        meta: {
          shouldLogin: true,
          isList: true
        },
        component: () => import(/* webpackChunkName: "SystemConfig" */ '../views/admin/system/Config.vue')
      }
    ]
  },
  {
    path: '/merchant',
    name: 'Merchant',
    component: () =>
      import(/* webpackChunkName: "Merchant" */ '../views/merchant/Index.vue'),
    children: [
      {
        path: '/merchant',
        redirect: { name: 'MerchantDashboard' },
      },
      {
        path: '/merchant/dashboard',
        name: 'MerchantDashboard',
        meta: {
          shouldLogin: true,
        },
        component: () => import(/* webpackChunkName: "MerchantDashboard" */ '../views/merchant/dashboard/Dashboard.vue')
      },
      {
        path: '/merchant/setting',
        name: 'MerchantSetting',
        meta: {
          shouldLogin: true,
        },
        component: () => import(/* webpackChunkName: "MerchantSetting" */ '../views/merchant/setting/Setting.vue')
      },
      {
        path: '/merchant/prompt',
        name: 'MerchantPrompt',
        meta: {
          shouldLogin: true,
        },
        component: () => import(/* webpackChunkName: "MerchantPrompt" */ '../views/merchant/prompt/Prompt.vue')
      },
      {
        path: '/merchant/dataset',
        name: 'MerchantDocument',
        component: () =>
          import(/* webpackChunkName: "Merchant" */ '../views/merchant/dataset/Index.vue'),
        children: [
          {
            path: '/merchant/dataset',
            redirect: { name: 'MerchantFileDocument' },
          },
          {
            path: '/merchant/dataset/document',
            name: 'MerchantFileDocument',
            meta: {
              shouldLogin: true,
            },
            component: () => import(/* webpackChunkName: "MerchantFileDocument" */ '../views/merchant/dataset/document/Document.vue'),
            children: []
          },
          {
            path: '/merchant/dataset/document/:id',
            name: 'MerchantFileDocumentDetail',
            meta: {
              shouldLogin: true,
            },
            component: () => import(/* webpackChunkName: "MerchantFileDocumentDetail" */ '../views/merchant/dataset/document/DocumentDetail.vue')
          },
          {
            path: '/merchant/dataset/web',
            name: 'MerchantWebDocument',
            meta: {
              shouldLogin: true,
            },
            component: () => import(/* webpackChunkName: "MerchantWebDocument" */ '../views/merchant/dataset/web/WebDocument.vue')
          },
          {
            path: '/merchant/dataset/web/:id',
            name: 'MerchantWebDocumentDetail',
            meta: {
              shouldLogin: true,
            },
            component: () => import(/* webpackChunkName: "MerchantWebDocumentDetail" */ '../views/merchant/dataset/web/WebDocumentDetail.vue')
          }
        ]
      },
      {
        path: '/merchant/multilingual',
        name: 'MerchantMultilingual',
        meta: {
          shouldLogin: true,
        },
        component: () => import(/* webpackChunkName: "MerchantMultilingual" */ '../views/merchant/multilingual/Multilingual.vue')
      },
      {
        path: '/merchant/welcome',
        name: 'MerchantWelcome',
        meta: {
          shouldLogin: true,
        },
        component: () => import(/* webpackChunkName: "MerchantWelcome" */ '../views/merchant/welcome/Welcome.vue')
      },
      {
        path: '/merchant/common-question',
        name: 'MerchantCommonQuestion',
        meta: {
          shouldLogin: true,
        },
        component: () => import(/* webpackChunkName: "MerchantCommonQuestion" */ '../views/merchant/commonQuestion/CommonQuestion.vue')
      },
      {
        path: '/merchant/chat-history',
        name: 'MerchantChatHistory',
        meta: {
          shouldLogin: true,
        },
        component: () => import(/* webpackChunkName: "MerchantChatHistory" */ '../views/merchant/chatHistory/ChatHistory.vue')
      },
    ]
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
