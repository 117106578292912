export default function (api) {
  return {
    login(data, headers) {
      return api.post('/login', data, { headers, shouldAuth: false })
    },
    upload(id, data) {
      return api.post('/merchant/' + id + '/upload', data, { shouldAuth: true })
    },
    uploadPreview(data) {
      return api.post('/upload/preview', data, { shouldAuth: true })
    },
    createMerchant(data) {
      return api.post('/merchant', data, { shouldAuth: true })
    },
    updateMerchant(id, data) {
      return api.put('/merchant/' + id, data, { shouldAuth: true })
    },
    checkMerchantMultilingual(id, data) {
      return api.post('/merchant/' + id + '/multilingual/check', data, { shouldAuth: true })
    },
    deleteMerchant(id) {
      return api.delete('/merchant/' + id, { shouldAuth: true })
    },
    getMerchantList(data) {
      return api.get('/merchant', { params: data, shouldAuth: true })
    },
    getMerchantDetail(id) {
      return api.get('/merchant/' + id, { shouldAuth: true })
    },
    downloadDocument(merchant_id, document_id) {
      return api.get(`/merchant/${ merchant_id }/document/${ document_id }/download`, {
        responseType: 'blob',
        shouldAuth: true
      })
    },
    deleteDocument(merchant_id, document_id) {
      return api.delete(`/merchant/${ merchant_id }/document/${ document_id }`, { shouldAuth: true })
    },
    getMerchantDashboard(merchant_id, data) {
      return api.get(`/merchant/${ merchant_id }/dashboard`, { params: data, shouldAuth: true })
    },
    getMerchantPrompt(merchant_id, data) {
      return api.get(`/merchant/${ merchant_id }/prompt`, { params: data, shouldAuth: true })
    },
    updateMerchantPrompt(merchant_id, data) {
      return api.put(`/merchant/${ merchant_id }/prompt`, data, { shouldAuth: true })
    },
    getMerchantDocument(merchant_id, data) {
      return api.get(`/merchant/${ merchant_id }/document`, { params: data, shouldAuth: true })
    },
    getMerchantDocumentPreview(merchant_id, document_id, data) {
      return api.get(`/merchant/${ merchant_id }/document/${ document_id }/preview`, {
        params: data,
        shouldAuth: true
      })
    },
    getMerchantWebDocumentPreview(merchant_id, document_id, data) {
      return api.get(`/merchant/${ merchant_id }/web_document/${ document_id }/preview`, {
        params: data,
        shouldAuth: true
      })
    },
    getMerchantWebDocument(merchant_id, data) {
      return api.get(`/merchant/${ merchant_id }/web_document`, { params: data, shouldAuth: true })
    },
    getMerchantWebDocumentDetail(merchant_id, document_id, data) {
      return api.get(`/merchant/${ merchant_id }/web_document/${ document_id }`, { params: data, shouldAuth: true })
    },
    getMerchantMultilingual(merchant_id, data) {
      return api.get(`/merchant/${ merchant_id }/multilingual`, { params: data, shouldAuth: true })
    },
    updateMerchantMultilingual(id, data) {
      return api.put('/merchant/' + id + '/multilingual', data, { shouldAuth: true })
    },
    documentUpload(merchant_id, data) {
      return api.post(`/merchant/${ merchant_id }/document`, data, {
        headers: { 'Content-Type': 'multipart/form-data' },
        shouldAuth: true
      })
    },
    documentUpdate(merchant_id, document_id, data) {
      return api.put(`/merchant/${ merchant_id }/document/${ document_id }`, data, {
        headers: { 'Content-Type': 'multipart/form-data' },
        shouldAuth: true
      })
    },
    webDocumentUpload(merchant_id, data) {
      return api.post(`/merchant/${ merchant_id }/web_document`, data, {
        shouldAuth: true
      })
    },
    webDocumentUpdate(merchant_id, document_id, data) {
      return api.put(`/merchant/${ merchant_id }/web_document/${ document_id }`, data, {
        shouldAuth: true
      })
    },
    webDocumentReEmbedding(merchant_id, document_id) {
      return api.post(`/merchant/${ merchant_id }/web_document/${ document_id }/re_embedding`, {
        shouldAuth: true
      })
    },
    deleteWebDocument(merchant_id, document_id) {
      return api.delete(`/merchant/${ merchant_id }/web_document/${ document_id }`, { shouldAuth: true })
    },

    // config
    getConfig() {
      return api.get('/config', { shouldAuth: true })
    },
    updateConfig(data) {
      return api.put('/config', data, { shouldAuth: true })
    },
    // chat history
    getChatHistory(merchant_id, data) {
      return api.get(`/merchant/${ merchant_id }/chatHistory`, { params: data, shouldAuth: true })
    },
    downloadChatHistory(merchant_id, data) {
      return api.get(`/merchant/${ merchant_id }/chatHistory/excel`, {
        params: data,
        responseType: 'blob',
        shouldAuth: true
      })
    },
    // welcome list
    getWelcomeList(merchant_id, data) {
      return api.get(`/merchant/${ merchant_id }/welcome`, { params: data, shouldAuth: true })
    },
    createWelcome(merchant_id, data) {
      return api.post(`/merchant/${ merchant_id }/welcome`, data, { shouldAuth: true })
    },
    updateWelcome(merchant_id, id, data) {
      return api.put(`/merchant/${ merchant_id }/welcome/${ id }`, data, { shouldAuth: true })
    },
    deleteWelcome(merchant_id, id) {
      return api.delete(`/merchant/${ merchant_id }/welcome/${ id }`, { shouldAuth: true })
    },

    // common question list
    getCommonQuestionList(merchant_id, data) {
      return api.get(`/merchant/${ merchant_id }/commonQuestion`, { params: data, shouldAuth: true })
    },
    createCommonQuestion(merchant_id, data) {
      return api.post(`/merchant/${ merchant_id }/commonQuestion`, data, { shouldAuth: true })
    },
    updateCommonQuestion(merchant_id, id, data) {
      return api.put(`/merchant/${ merchant_id }/commonQuestion/${ id }`, data, { shouldAuth: true })
    },
    deleteCommonQuestion(merchant_id, id) {
      return api.delete(`/merchant/${ merchant_id }/commonQuestion/${ id }`, { shouldAuth: true })
    },
    // platform list
    getPlatformDetail(platform_id, data) {
      return api.get(`/platform/${ platform_id }`, { params: data, shouldAuth: true })
    },
    getPlatformList(data) {
      return api.get(`/platform`, { params: data, shouldAuth: true })
    },
    createPlatform(data) {
      return api.post(`/platform`, data, { shouldAuth: true })
    },
    updatePlatform(platform_id, data) {
      return api.put(`/platform/${ platform_id }`, data, { shouldAuth: true })
    },
  }
}
