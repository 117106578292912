import { createStore } from 'vuex'
import { authApi } from "@/api";

export default createStore({
  state: {
    config: {},
    merchant: null
  },
  getters: {
    config: (state) => state.config,
    merchant: (state) => state.merchant
  },
  mutations: {
    setConfig(state, data) {
      state.config = data;
    },
    setMerchant(state, data) {
      state.merchant = data;
    }
  },
  actions: {
    setConfig({ commit }, data) {
      authApi.getConfig().then(res => {
        commit("setConfig", res.data);
      })
    },
    setMerchant({ commit }, data) {
      authApi.getMerchantDetail(data).then(res => {
        commit("setMerchant", res.data);
      })
    }
  },
  modules: {}
})
